/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    gen: 'Genul Epic',
    subiecte: [
      'Perspectiva narativă',
      'Modalități de caracterizare a personajului',
    ],
  },
  {
    gen: 'Genul Liric',
    subiecte: [
      'Relația dintre ideea poetică și mijloacele expresivității',
    ],
  },
  {
    gen: 'Genul Dramatic',
    subiecte: [
      'Rolul notațiilor autorului',
      'Modalități de caracterizare a personajului',
    ],
  },
];
