/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    an: 2021,
    numarMaxSubiecte: 12,
  },
  {
    an: 2020,
    numarMaxSubiecte: 20,
  },
];
