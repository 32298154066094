/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    an: 2021,
    sesiuni: [
      'Sesiune Specială',
      'Sesiune Vară',
      'Sesiune Toamnă',
    ],
  },
  {
    an: 2020,
    sesiuni: [
      'Sesiune Specială',
      'Sesiune Vară',
      'Sesiune Toamnă',
    ],
  },
  {
    an: 2019,
    sesiuni: [
      'Sesiune Specială',
      'Sesiune Vară',
      'Sesiune Toamnă',
    ],
  },
  {
    an: 2018,
    sesiuni: [
      'Sesiune Specială',
      'Sesiune Vară',
      'Sesiune Toamnă',
    ],
  },
  {
    an: 2017,
    sesiuni: [
      'Sesiune Specială',
      'Sesiune Vară',
      'Sesiune Toamnă',
    ],
  },
  {
    an: 2016,
    sesiuni: [
      'Sesiune Specială',
      'Sesiune Vară',
      'Sesiune Toamnă',
    ],
  },
];
